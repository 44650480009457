/** @module routes */

/**
 * @typedef Route
 * @type {object}
 * @property {string} exactPathname - exact path to use as path prop in Route components
 * @property {string} pathname - full path to use when redirecting to it. Do not set it yourself!
 * @property {string} template - name of template
 * @property {Object.<string, Route>} [children] - nested routes
 * */

/**
 * @typedef RouteTemplate
 * @type {object}
 * @property {string} name - name of template. Have to be the same as key
 * @property {string} exactPathname - template path
 * */

// type Route = {
//     exactPathname: string; pathname to use as path prop in Route components
//     pathname: string; full pathname to use when redirecting to it
//     children: Route[]
// }

const TEMPLATE_NAMES = {
  BACK_BUTTON_TEMPLATE: 'backButtonTemplate',
};

/** @type {Object.<string, RouteTemplate>} */
const routeDetailsTemplate = {
  [TEMPLATE_NAMES.BACK_BUTTON_TEMPLATE]: {
    exactPathname: '/back-button-template',
  },
};

/** @type {Object.<string, Route>} */
const routesDetails = {
  home: {
    exactPathname: '/',
  },
  dashboard: {
    exactPathname: '/dashboard',
  },
  detailPromotion: {
    exactPathname: '/detail-promotion',
    template: TEMPLATE_NAMES.BACK_BUTTON_TEMPLATE,
  },
  notFound: {
    exactPathname: '*',
  },
};

/** @type {Object.<string, Route>} */
const routes = {};

function generateChildPathname(childRoutes, parentRoute) {
  Object.entries(childRoutes).forEach(([childRouteName, childRoute]) => {
    const { exactPathname, children } = childRoute;
    const resultChildRoute = { ...childRoute };
    let childExactPathname = exactPathname;
    if (childExactPathname.startsWith('/')) {
      childExactPathname = childExactPathname.slice(1);
    }
    resultChildRoute.exactPathname = childExactPathname;
    resultChildRoute.pathname = `${parentRoute.pathname}/${childExactPathname}`;
    routes[childRouteName] = resultChildRoute;
    if (children && Object.keys(children).length) {
      generateChildPathname(children, resultChildRoute);
    }
  });
}

Object.entries(routesDetails).forEach(([routeName, route]) => {
  const { exactPathname, children, template } = route;
  routes[routeName] = {
    ...routesDetails[routeName],
  };
  let pathname = exactPathname;
  if (template) {
    pathname = `${routeDetailsTemplate[template].exactPathname}${exactPathname}`;
    routes[routeName].exactPathname = exactPathname.slice(1);
  }
  routes[routeName].pathname = pathname;
  if (children && Object.keys(children).length) {
    generateChildPathname(children, routes[routeName]);
  }
});

export { routeDetailsTemplate as routesTemplate };
export default routes;
