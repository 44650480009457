import SUBSTITUTION_TYPES from '../utils/substituteLocales/substitutionTypes';
import { faqLinks } from '../constants/links';

export default {
  commonTexts: {
    savingBlock: {
      text: 'There are unsaved changes in this section',
      buttons: {
        save: 'Save',
        cancel: 'Discard',
      },
    },
    button: {
      save: 'Save',
      cancel: 'Cancel',
      back: 'Back',
    },
    tableProductsHeader: {
      name: 'Name',
      vendorCode: 'SKU',
      price: 'Price',
      action: '',
    },
    multiselect: {
      submitButton: 'Add to promo',
      cancelButton: 'Cancel',
      selectAllButton: 'Select all',
      selectNoneButton: 'Select none',
      products: {
        placeholder: 'Product name, SKU, UPC',
        pageText: 'Page',
        emptyList: {
          title: "There isn't such product",
          description: 'Try a different name or clear your search to see all products',
        },
      },
      categories: {
        placeholder: 'Category title',
        emptyList: {
          title: "There isn't such category",
          description: 'Try a different name or clear your search to see all categories',
        },
      },
    },
  },
  validation: {
    required: 'Required to fill',
    coupon: {
      required: 'Coupon code cannot be empty ',
      alreadyExist: 'The code is in use',
    },
  },
  indexPage: {
    bundlesAreaTitle: 'Product bundles',
    bundlesAreaSubtitle:
      'You can edit and delete bundles, as well as enable and disable them without deleting',
    pageTitle: 'Product Bundles',
    addLabelAreaTitle: 'Highlight your products',
    addLabelAreaSubtitle:
      'Combine different items to sell them together with a discount. Motivate customers to add more products to their carts and spend more money!',
    faqAreaTitle: 'FAQ',
    faqAreaSubtitle: {
      html: 'A detailed guide on how to use an app. If you need any help or have any problems please don\'t hesitate to <a href="https://simcase-apps.omnidesk.ru/?send=question" target="_blank">contact us</a> directly. We are here to help',
    },
    faq: {
      question1: {
        title: 'Promo Item Header',
        content:
          'Advertise and sell your products directly through your page and reach billions of potential customers. Advertise and sell your products directly through your page and reach billions of potential customers.',
      },
    },
    iconableBlockTitle: 'Bundle products',
    iconableBlockDescription:
      'Create bundles that make sense, such as holiday sets or complementary goods combo. Help your customers to find the best addition to their carts and offer the discount for the bundle',
    addNewBundle: 'Create product bundles',
    card: {
      action: 'Actions',
      actionUpdate: 'Edit',
      actionDelete: 'Remove',
    },
    removeBundleModal: {
      label: 'Do you really want to remove this bundle?',
      agreeButton: 'Agree',
      cancelButton: 'Cancel',
    },
  },
  homePage: {
    title: 'Buy One Get One Free: Sales Promotions',
    addToShopArea: {
      title: 'Create a new promotion',
      subtitle:
        'First, choose the products the customer has to buy to participate in the promotion. Second, choose the products the customer will get for free or at a reduced price',
      interactiveBlock: {
        title: 'Boost your sales and profit',
        subtitle:
          'Motivate your customers to buy specific products by offering them a gift or a discount for another set of products. BOGOF is a proven strategy to increase the number of items per order!',
        buttonText: 'Set Up Promotion',
      },
    },
    promotionsArea: {
      title: 'Manage BOGOF promotions',
      subtitle:
        'Easily enable or disable any promotion. You can also change the size of the discount, the list of products, or description',
      interactiveBlock: {
        actionButton: {
          title: 'Actions',
          actionsList: {
            edit: 'Edit',
            delete: 'Delete',
          },
        },
      },
      dialogDelete: {
        title: 'Do you really want to remove the promotion ?',
        submitButton: 'Yes',
        cancelButton: 'No',
      },
    },
    shopRevisionArea: {
      title: 'Offering discounts may not be enough',
      subtitle: 'Show your customers some goods they would love to add to their carts!',
      interactiveBlock: {
        title: 'Manual recommendations tool',
        subtitle:
          'Help your customers to collect all items for 1+1=3 promotion by showing them accessories, complementary products, or higher-end alternatives!',
        buttonText: 'Recommend products',
      },
    },
    bannerAreaTheFirst: {
      title: 'Useful tool to automate your business',
      subtitle:
        'Set up and manage product ribbons easier than ever! Ribbons are perfect to highlight your new products, items on sale, or bestsellers. Say goodbye to the tedious and time-consuming manual creation of ribbons for each product. Instead, manage them all at once with our app',
      buttonText: 'Automate ribbons',
    },
  },
  detailPromotionPage: {
    title: 'Settings',
    setupPromotionArea: {
      title: 'Set up the promotion',
      subtitle: 'Set the discount value and add a short description',
      interactiveBlock: {
        section_1: {
          toggleHint: 'Status (Enabled / Disabled)',
        },
        section_2: {
          title_1: 'Promotion',
          parameter: {
            title: 'Discount type',
            option_1: 'Percentage',
            option_2: 'Fixed amount',
          },
          meaning: {
            title: 'Value',
            hint_1: '(100% means a customer will get the product for free)',
          },
          title_2: 'Description',
          description:
            'Short description (up to 255 characters) will be displayed at checkout, when a customer adds all the products required for the promotion to their cart',
          descriptionPlaceholder: 'Enter a description',
        },
      },
    },
    setupPurchaseArea: {
      title: 'Manage promotion conditions',
      subtitle:
        'Select BUY-products, which are the products (or product categories) a customer has to buy to participate in the promotion. Specify a minimum quantity of these products that enables the promotion',
      interactiveBlock: {
        productsAmount: {
          title: 'Number of BUY-products',
          subtitle:
            'Enter a number of products that a customer has to buy to participate in the promotion',
        },
        productsBinding: {
          title: 'BUY-products',
          subtitle: 'Select products a customer has to buy to participate in the promotion',
        },
        productCategoriesBinding: {
          title: 'BUY-categories',
          subtitle:
            'Select products from which categories a customer has to buy to participate in the promotion',
        },
      },
    },
    setupDiscountArea: {
      title: 'Manage promotional discounts',
      subtitle:
        'Select GET-products, which are the products (or product categories) a customer can get a discount on. Discounted price will be displayed at checkout',
      interactiveBlock: {
        productsBinding: {
          title: 'GET-products',
          subtitle: 'Select products a customer will get a discount on',
        },
        productCategoriesBinding: {
          title: 'GET-categories',
          subtitle: 'Select product categories a discount will be applied to',
        },
        productManualBinding: {
          title: 'Manual linking of products',
          subtitle: 'Select the products for which the discount will be active.',
        },
      },
    },
    setupDisplayPromotionArea: {
      title: 'Choose promotion type',
      subtitle: 'Specify, whether a promo code is required to enable BOGOF promotion',
      interactiveBlock: {
        title: 'Promotion type',
        subtitle:
          'Select “Automatic Discount” to make the BOGOF promotion available to all your customers. Select “Discount Coupon” to require a promo code for participation',
        displayTypeSelectBox: {
          option_1: 'Automatic discount',
          option_2: 'Discount coupon',
        },
        couponInput: {
          title: 'Enter a coupon code (promo code) to enable the promotion',
          placeholder: 'any combination of letters/numbers or a word',
        },
      },
    },
    FAQ_Area: {
      title: 'FAQ',
      subtitle:
        "A detailed guide on how to use the app. If you cannot find the answer you are looking for, don't hesitate to contact us directly. We are here to help",
      substitutions: [
        {
          type: SUBSTITUTION_TYPES.LINK,
          name: 'contact us',
          text: 'contact us',
          payload: {
            href: faqLinks.contactUs,
            target: '_blank',
          },
        },
      ],
      whenDiscountApplying: {
        title: 'How can a customer get a discounted product from the GET-list?',
        description: 'Customers should add both BUY- and GET-products to their carts. Discounted prices will be displayed in the cart. Discounts will not be applied unless the products are in the cart.'
      },
      howDiscountApplying: {
        title: 'If a customer adds more than one product from the GET-list to their cart, which product will the discount be applied to?',
        description: 'The discount will only be applied to the cheapest item of the GET-products in the cart.'
      },
      discountNotWork: {
        title: "Why isn't my fixed discount being applied?",
        description: 'If you have chosen a fixed discount, please be careful when adding products to the GET-list. The fixed discount cannot be more than the price of the product. For example, if the discount value is $100 and the price of the cheapest GET-product in the cart is $50, the customer will only get the cheapest item for free (so the discount value will be $50).'
      }
    },
  },
};
