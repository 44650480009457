import billings from '../constants/billings';
import { setEcwidAppID } from '../constants/ecwidAppID';

function initEcwidAppID(billing, environment) {
  const developExtAppID = 'simcase-dev7';
  const developIntAppID = '';
  const productionExtAppID = 'advanced-discounts-dev';
  const productionIntAppID = 'buy-one-get-one';
  switch (billing) {
    case billings.ext:
      if (environment === 'production') {
        setEcwidAppID(productionExtAppID);
      } else if (environment === 'develop') {
        setEcwidAppID(developExtAppID);
      }
      break;
    case billings.int:
      if (environment === 'production') {
        setEcwidAppID(productionIntAppID);
      } else if (environment === 'develop') {
        setEcwidAppID(developIntAppID);
      }
      break;
    // no default
  }
}

export default initEcwidAppID;
