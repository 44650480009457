import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

/**
 * Method create path with dynamic parameters and search parameters
 *
 * @memberOf module:routes
 * @param {string} pathname - path
 * @param {Object} dynamicValues - dynamic values to be replaced in the path
 * @param {Object.<string, string>} [addedSearchParams=null] - parameters to be added to the path
 * @param {string|string[]} [removedSearchParams=null] - parameters to be removed from the path
 *
 * @returns {string}
 * */
function generatePathnameDynamic(
  pathname,
  dynamicValues,
  addedSearchParams = null,
  removedSearchParams = null
) {
  let resultPathname = pathname;

  Object.entries(dynamicValues).forEach(([key, value]) => {
    resultPathname = resultPathname.replace(`:${key}`, value);
  });
  if (addedSearchParams || removedSearchParams) {
    resultPathname = generatePathnameWithSearch(
      resultPathname,
      addedSearchParams,
      removedSearchParams
    );
  }
  return resultPathname;
}

/**
 * Method create path with search parameters
 *
 * @memberOf module:routes
 * @param {string} pathname - path
 * @param {Object.<string, string>} [addedSearchParams] - parameters to be added to the path
 * @param {string|string[]} [removedSearchParams] - parameters to be removed from the path
 * @returns {string}
 * */
function generatePathnameWithSearch(pathname, addedSearchParams, removedSearchParams) {
  let urlStringifyObject = '';
  let searchParams = history.location.search;

  if (addedSearchParams && typeof addedSearchParams === 'object') {
    urlStringifyObject = new URLSearchParams(addedSearchParams).toString();
  }

  if (urlStringifyObject) {
    searchParams = `${searchParams}${!searchParams ? '?' : '&'}${urlStringifyObject}`;
  }

  if (removedSearchParams) {
    searchParams = new URLSearchParams(searchParams);
    if (typeof removedSearchParams === 'string') {
      searchParams.delete(removedSearchParams);
    } else if (Array.isArray(removedSearchParams)) {
      removedSearchParams.forEach((removedSearchParam) => {
        searchParams.delete(removedSearchParam);
      });
    }
    if (searchParams.toString()) {
      searchParams = `?${searchParams}`;
    }
  }

  return `${pathname}${searchParams}`;
}

export { generatePathnameDynamic, generatePathnameWithSearch };
export default history;
