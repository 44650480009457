import { setBaseApiUrl } from '../constants/baseApiUrl';
import { setBaseAppPath } from '../constants/baseAppPath';
import billings from '../constants/billings';
import environment from '../constants/environment';

function initBilling() {
  const { location } = window;
  const { pathname } = location;

  const locationStartsWith = { ext: false, int: false };
  locationStartsWith.ext = pathname.startsWith(`/${billings.ext}`);
  locationStartsWith.int = pathname.startsWith(`/${billings.int}`);

  const isSuccessBilling = locationStartsWith.ext || locationStartsWith.int;
  let billing = '';

  if (isSuccessBilling) {
    let baseApiUrl = `${environment.REACT_APP_BASE_API_URL}`;
    let baseAppPath = '';

    if (locationStartsWith.ext) {
      baseApiUrl = `${baseApiUrl}/${billings.ext}`;
      baseAppPath = billings.ext;
      billing = billings.ext;
    } else if (locationStartsWith.int) {
      baseApiUrl = `${baseApiUrl}/${billings.int}`;
      baseAppPath = billings.int;
      billing = billings.int;
    }

    setBaseApiUrl(baseApiUrl);
    setBaseAppPath(baseAppPath);

    return { success: true, billing };
  }

  return { success: false, billing };
}

export default initBilling;
