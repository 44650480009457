import initEcwidApp from './initEcwidApp';
import initI18N from './i18n';
import initSentry from './sentry';
import store from '../store';
import api, { apiInstance } from '../api';
import getSearchParams from '../utils/getSearchParams';
import searchParamsConstant from '../constants/searchParams';
import { setProfileData } from '../store/slices/profileSlice';
import mainPreloader from '../utils/mainPreloader';
import initBilling from './initBilling';
import { getBaseApiUrl } from '../constants/baseApiUrl';
import history, { generatePathnameWithSearch } from '../router/history';
import sentryCaptureError from '../utils/sentryCaptureError';
import initEcwidAppID from './initEcwidAppID';
import environment from '../constants/environment';

async function performBoots() {
  const { success: isInitBillingSuccess, billing } = initBilling();
  initSentry();
  initI18N();
  if (isInitBillingSuccess) {
    initEcwidAppID(billing, environment.REACT_APP_APPLICATION_ENVIRONMENT);
    apiInstance.baseURL = getBaseApiUrl();
    const { EcwidApp } = await initEcwidApp(false, false);
    const ecwidPayload = getSearchParams(searchParamsConstant.ecwidPayload);
    const baseParams = { payload: ecwidPayload };
    apiInstance.setBaseParams(baseParams);
    const profileData = await api.ecwidRepository.getProfile();
    store.dispatch(setProfileData(profileData));
    mainPreloader.showMainPreloader();
    EcwidApp.ready();
  } else {
    const wrongBillingError = new Error('Wrong billing');
    sentryCaptureError(wrongBillingError);
    // eslint-disable-next-line no-console
    console.error(wrongBillingError);
    const notFoundPath = generatePathnameWithSearch('not-found');
    history.push(notFoundPath);
  }
}
export default performBoots;
