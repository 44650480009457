/**
 * Class representing Service
 * @memberOf module:api
 * */
class Service {
  /**
   * Create Service instance
   *
   * @param {string} api - Axios Api instance
   * @param {string} [baseURL=''] - Base api url for requests sent through this Service
   * */
  constructor(api, baseURL = '') {
    this._baseURL = baseURL;
    this._api = api;
  }

  /**
   * Sends request
   * @param {Object} data - Request payload
   * */
  async request(data) {
    if (this._baseURL) {
      data.baseURL = this._baseURL;
    }
    return await this._api.request(data);
  }
}

export default Service;
