import i18n from 'i18next';

const promotionsDTOBackendErrors = {
  setPromotionSettings(error) {
    const { language } = i18n;
    const { translation } = i18n.store.data[language];
    const alreadyExistErrorMessage = translation.validation.coupon.alreadyExist;

    const { advanced } = error.body;
    if (error.body?.status === 422) {
      const couponKeyName = 'type.value';
      if (couponKeyName in advanced) {
        error.body.advanced[couponKeyName].splice(0, 1, alreadyExistErrorMessage);
      }
    }

    return error;
  },
};

export default promotionsDTOBackendErrors;
