import Repository from './Repository';
import promotionsDTO from '../../DTOs/promotion/promotionsDTO';
import apiURLs from '../../constants/apiURLs';
import promotionsDTOBackendErrors from '../../DTOs/promotion/promotionsDTOBackendErrors';

class PromotionRepository extends Repository {
  getPromotionsList = async () => {
    const data = {
      url: apiURLs.promotion.getPromotionsList,
    };
    const response = await this.request.get(this.services.baseService, data);
    return promotionsDTO.getPromotionsList(response.data.items);
  };

  setPromotionEnable = async (promotionID, enable) => {
    const data = {
      url: enable
        ? apiURLs.promotion.enablePromotion(promotionID)
        : apiURLs.promotion.disablePromotion(promotionID),
    };
    await this.request.post(this.services.baseService, data);
  };

  createPromotion = async () => {
    const data = {
      url: apiURLs.promotion.createPromotion,
    };
    const response = await this.request.post(this.services.baseService, data);
    return promotionsDTO.createPromotion(response.data);
  };

  deletePromotion = async (promotionID) => {
    const data = {
      url: apiURLs.promotion.deletePromotion(promotionID),
    };
    await this.request.delete(this.services.baseService, data);
  };

  getPromotion = async (promotionID) => {
    const data = {
      url: apiURLs.promotion.getPromotion(promotionID),
    };
    const response = await this.request.get(this.services.baseService, data);
    return promotionsDTO.getPromotion(response.data);
  };

  setPromotionSettings = async (promotionID, promotion) => {
    const promotionRemote = promotionsDTO.setPromotionSettings(promotion);
    const data = {
      url: apiURLs.promotion.setPromotion(promotionID),
      data: promotionRemote,
    };
    try {
      return await this.request.post(this.services.baseService, data);
    } catch (e) {
      throw promotionsDTOBackendErrors.setPromotionSettings(e);
    }
  };

  setCategories = async (promotionID, categoriesData) => {
    const setCategoriesData = promotionsDTO.setCategories(categoriesData);
    const data = {
      url: apiURLs.promotion.setPromotionCategories(promotionID),
      data: setCategoriesData,
    };
    await this.request.post(this.services.baseService, data);
  };

  setProducts = async (promotionID, productsData) => {
    const setProductsData = promotionsDTO.setProducts(productsData);
    const data = {
      url: apiURLs.promotion.setPromotionProducts(promotionID),
      data: setProductsData,
    };
    await this.request.post(this.services.baseService, data);
  };

  getProductsList = async (promotionID, type, search, page) => {
    const getProductsListParams = promotionsDTO.getProductsList.params(
      promotionID,
      type,
      search,
      page
    );
    const data = {
      url: apiURLs.promotion.searchPromotionProducts(promotionID),
      params: getProductsListParams,
    };
    const requestKey = `getProductsList_${type}`;
    const response = await this.requestAborted.get(requestKey, this.services.baseService, data);
    return promotionsDTO.getProductsList.remoteToDomain(response.data);
  };

  getCategoriesList = async (promotionID, type, search) => {
    const getCategoriesListParams = promotionsDTO.getCategoriesList.params(type, search);
    const data = {
      url: apiURLs.promotion.searchPromotionCategories(promotionID),
      params: getCategoriesListParams,
    };
    const requestKey = `getCategoriesList_${type}`;
    const response = await this.requestAborted.get(requestKey, this.services.baseService, data);
    return promotionsDTO.getCategoriesList.remoteToDomain(response.data);
  };
}

export default PromotionRepository;
