import Service from './Service';

function createServices(api) {
  return {
    exampleService: new Service(api, ''),
    baseService: new Service(api, ''),
  };
}

export default createServices;
