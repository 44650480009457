const PRODUCT_TYPES = {
  BUY: 'for_buying',
  DISCOUNT: 'for_getting',
};

const CATEGORY_TYPES = {
  BUY: 'for_buying',
  DISCOUNT: 'for_getting',
};
export { PRODUCT_TYPES, CATEGORY_TYPES };
