import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  page: {
    composes: 'settings-page',
  },
  footer: {
    height: 40,
  },
});

export default useStyles;
