import getSearchParams from '../../utils/getSearchParams';
import searchParamsConstant from '../../constants/searchParams';
import { PRODUCT_TYPES, CATEGORY_TYPES } from '../../constants/productAndCategoryType';
import store from '../../store';

const promotionsDTO = {
  getPromotionsList(promotionsListRemote) {
    return promotionsListRemote.map((promotionRemote) =>
      this.promotionRemoteToPromotionShort(promotionRemote)
    );
  },
  getPromotion(promotionRemote) {
    return this.promotionRemoteToPromotion(promotionRemote);
  },
  createPromotion(promotionRemote) {
    return this.promotionRemoteToPromotion(promotionRemote);
  },
  setPromotionSettings(promotion) {
    return this.promotionToPromotionRemote(promotion);
  },
  setProducts(productsData) {
    const { productsToBuy, productsToDiscount } = productsData;
    let products;
    let type;
    if (productsToBuy !== undefined && Array.isArray(productsToBuy)) {
      products = productsToBuy;
      type = PRODUCT_TYPES.BUY;
    }
    if (productsToDiscount !== undefined && Array.isArray(productsToDiscount)) {
      products = productsToDiscount;
      type = PRODUCT_TYPES.DISCOUNT;
    }
    return {
      type,
      productIds: products.map((product) => product.id),
    };
  },
  setCategories(categoriesData) {
    const { categoriesToBuy, categoriesToDiscount } = categoriesData;
    let categories;
    let type;
    if (categoriesToBuy !== undefined && Array.isArray(categoriesToBuy)) {
      categories = categoriesToBuy;
      type = CATEGORY_TYPES.BUY;
    }
    if (categoriesToDiscount !== undefined && Array.isArray(categoriesToDiscount)) {
      categories = categoriesToDiscount;
      type = CATEGORY_TYPES.DISCOUNT;
    }
    return {
      type,
      categoryIds: categories.map((category) => category.id),
    };
  },
  getProductsList: {
    remoteToDomain(data) {
      const { items: products, currentPage, perPage, lastPage, total } = data;
      const { currencySuffix, currencyPrefix } = store.getState().profileReducer.formatsAndUnits;
      const pagination = {
        currentPage,
        perPage,
        lastPage,
        total,
      };
      return {
        pagination,
        products: products.map((product) =>
          promotionsDTO.productRemoteToProduct(product, { currencyPrefix, currencySuffix })
        ),
      };
    },
    params(promotionID, type, search, page) {
      const params = {};
      if (!promotionID) {
        params.promotionID = promotionID;
      } else {
        params.promotionID = getSearchParams(searchParamsConstant.promotionID) ?? '';
      }
      if (type && (type === PRODUCT_TYPES.BUY || PRODUCT_TYPES.DISCOUNT)) {
        params.type = type;
      } else {
        params.type = PRODUCT_TYPES.BUY;
      }
      params.search = search ?? '';
      params.page = page ?? 1;
      return params;
    },
  },
  getCategoriesList: {
    remoteToDomain(categoriesRemote) {
      return categoriesRemote;
    },
    params(type, search) {
      const params = {};
      if (type && (type === CATEGORY_TYPES.BUY || type === CATEGORY_TYPES.DISCOUNT)) {
        params.type = type;
      } else {
        params.type = CATEGORY_TYPES.BUY;
      }
      params.search = search ?? '';
      return params;
    },
  },
  promotionRemoteToPromotion(promotionRemote) {
    const { id, enable, parameter, description, amountGoods, type } = promotionRemote;
    let products = promotionRemote.products ?? {
      forBuying: { items: [] },
      forGetting: { items: [] },
    };
    let categories = promotionRemote.categories ?? { forBuying: [], forGetting: [] };
    const { name: parameterName, value: parameterValue } = parameter;
    const { name: typeName, value: typeValue } = type;
    const {
      forBuying: { items: productsToBuy },
      forGetting: { items: productsToDiscount },
    } = products;
    const { forBuying: categoriesToBuy, forGetting: categoriesToDiscount } = categories;
    const { currencySuffix, currencyPrefix } = store.getState().profileReducer.formatsAndUnits;

    return {
      id,
      enabled: enable,
      meaning: String(parameterValue),
      description,
      parameter: parameterName,
      productsAmount: String(amountGoods),
      productsToBuy: productsToBuy.map((productToBuyRemote) =>
        this.productRemoteToProduct(productToBuyRemote, { currencyPrefix, currencySuffix })
      ),
      categoriesToBuy: categoriesToBuy,
      productsToDiscount: productsToDiscount.map((productToDiscountRemote) =>
        this.productRemoteToProduct(productToDiscountRemote, { currencyPrefix, currencySuffix })
      ),
      categoriesToDiscount: categoriesToDiscount,
      displayType: typeName,
      coupon: String(typeValue ?? ''),
    };
  },
  promotionRemoteToPromotionShort(promotionRemote) {
    const { id, enable, description } = promotionRemote;
    return {
      id,
      enabled: enable,
      description,
    };
  },
  promotionToPromotionRemote(promotion) {
    const { description, parameter, meaning, productsAmount, displayType, coupon } = promotion;
    const promotionRemote = {};

    if (description !== undefined) {
      promotionRemote.description = description;
    }

    if (parameter !== undefined && meaning !== undefined) {
      promotionRemote.parameter = {
        name: parameter.key,
        value: parseFloat(meaning || '0'),
      };
    }

    if (productsAmount !== undefined) {
      promotionRemote.amountGoods = parseInt(productsAmount);
    }

    if (displayType !== undefined) {
      promotionRemote.type = {
        name: displayType,
      };
    }

    if (coupon !== undefined && coupon) {
      promotionRemote.type = { ...promotionRemote.type, value: coupon };
    }

    return promotionRemote;
  },

  productRemoteToProduct(productRemote, currency) {
    const { id, name, originalImageUrl, sku, price, combinations } = productRemote;
    let currencyPrefix;
    let currencySuffix;
    const imageUrl = originalImageUrl;
    if (!currency) {
      const { formatsAndUnits } = store.getState().profileReducer;
      currencyPrefix = formatsAndUnits.currencyPrefix;
      currencySuffix = formatsAndUnits.currencySuffix;
    } else {
      currencyPrefix = currency.currencyPrefix;
      currencySuffix = currency.currencySuffix;
    }
    const priceWithCurrency = `${currencyPrefix} ${price} ${currencySuffix}`;
    const product = {
      id,
      name,
      imageUrl,
      vendorCode: sku,
      price: priceWithCurrency,
      combinations: {},
    };
    product.combinations = combinations.map((combination) =>
      this.productCombinationRemoteToProductCombination(combination, product)
    );
    return product;
  },

  productCombinationRemoteToProductCombination(productCombinationRemote, product) {
    const { id, size, color, weight, height, volume, isChecked } = productCombinationRemote;
    const {
      name: productName,
      imageUrl: productImageUrl,
      vendorCode: productVendorCode,
      price: productPrice,
    } = product;

    return {
      id,
      size,
      color,
      weight,
      height,
      volume,
      price: productPrice,
      isChecked,
      name: productName,
      imageUrl: productImageUrl || '',
      vendorCode: productVendorCode,
    };
  },

  categoryRemoteToCategory(categoryRemote, withIsMatchFlag = false) {
    const { id, name, isMatch } = categoryRemote;
    const category = { id, name };
    if (withIsMatchFlag) {
      category.isMatch = isMatch;
    }
    return category;
  },
};

export default promotionsDTO;
